.nav-link {
    color: #fff;
    position: relative;
    cursor: pointer;
  }
  
  .nav-link::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease;
  }
  
  .nav-link:hover::before,
  .nav-link.active::before {
    width: 100%;
  }
  
  .nav-link.active::before {
    background-color: #fff;

  }